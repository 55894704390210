import { Button } from "primereact/button";
import "primeflex/primeflex.css";
import { useState } from "react";
import { useRef } from "react";
import axios from "axios";
// import { InputText } from "primereact/inputtext";
// import { FloatLabel } from "primereact/floatlabel";
import banner from "./assets/facia.webp";
// import { Image } from "primereact/image";
import ship from "./assets/ship_detection_new.gif";
import satellite_view from "./assets/satellite_view.webp";
import input_img from "./assets/port_input.jpg";
import output_img from "./assets/port_output.jpg";
// import Cookies from 'js-cookie';
import "primeicons/primeicons.css";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
// import { Splitter, SplitterPanel } from "primereact/splitter";
import satellite_svg from './assets/icons/satellite.svg';
import light from './assets/icons/lightning.svg';
import dish from './assets/icons/satellite-dish.svg';
// import orbit from './assets/icons/orbit.svg';
// import another from './assets/icons/another-satellite.svg';
// import ai from './assets/icons/artificial-intelligence.svg';
import ml from './assets/icons/ml.svg';
import orbit1 from './assets/icons/orbit1.svg';
import change from './assets/icons/change.svg';

// import { InputText } from "primereact/inputtext";
function App() {

  // const [value, setValue] = useState("");

  const ref = useRef(null);
  const handleClick2 = () => {
  ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("");
    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
            setEmailError("Invalid email format");
        } else {
            setEmailError("");
        }
    };
    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneError] = useState("")
    const handlePhoneChange = (e) => {
        const { value } = e.target;
        setPhone(value);
        const phoneRegex = /^[0-9+]{0,}$/;
        if (!phoneRegex.test(value)) {
            setPhoneError("Invalid phone number");
        } else {
            setPhoneError("");
        }
    }

    const [name, setName] = useState("")
    const [nameError, setNameError] = useState("");
    const handleNameChange = (e) => {
        const { value } = e.target;
        setName(value);
        const nameRegex = /^[A-Za-z ]{3,20}$/;
        if (!nameRegex.test(value)) {
            setNameError("Name should contain only alphabets.");
        } else {
            setNameError("");
        }
    };
    const [message, setMessage] = useState("")
    const [messageError, setMessageError] = useState("");
    const handleMessageChange = (e) => {
        const { value } = e.target;
        setMessage(value);
        if (!value.trim()) {
            setMessageError("Message cannot be empty");
        } else {
            setMessageError("");
        }
    };

  
    const handleClick = (e) => {
      e.preventDefault();
        //Cookies.remove('checking purpose');
        console.log(name, email, phone, message);
        const postData = {
            name: name,
            email: email,
            phone: phone,
            message: message,
        
        };

        if (name && email && phone && message ) {
         
                //setIsSubmitting(true)
                axios.post('https://suhora.com/send_mirka/', postData)
                    .then((response) => {
                        console.log(response);
                            setName("")
                            setEmail("")
                            setPhone("")
                            setMessage("")
                
                      //  setIsSubmitting(false)
                        alert("Thank you for your interest. We will get back to you shortly.")
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000)
                    })
                    .catch(() => {
                      console.log("error"+ postData);
                       // setIsSubmitting(false)
                        alert("Something went wrong. Please try again later.")
                    });
            
        } else {
           // setIsSubmitting(false)
            window.alert("Please fill all the fields")
        }
    };



  return (
    <>
      <div
        className="header-container p-0"
        style={{ position: "relative", padding: 0 }}
      >
        <img
          src={banner}
          alt="Header Image"
          className="header-image"
          style={{ width: "100%", height: "50%" }}
        />
        {/* <div className="header-overlay" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div className="header-text" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: 'white' }}>
          <h1>Welcome to [Your Event Name]</h1>
          <p>Discover our cutting-edge software solutions!</p>
        </div>
      </div> */}
      </div>
      <div
        className="grid grid-nogutter surface-800 text-800 items-center"
        style={{ marginTop: "-3px" }}
      >
        <div className="col-12 md:col-6 p-6 md:p-0 text-center md:text-left flex align-items-center ">
          <section>
            <span
              className="block text-5xl font-bold mb-1"
              style={{ color: "#1266a0" }}
            ></span>
            <div className="text-5xl text-primary font-bold mb-3 md:px-8">
              Excellence in Image & Intelligence
            </div>
            <p className="mt-0 mb-4 text-700 text-md line-height-3 text-gray-50 md:px-8">
              We provide state-of-the-art imaging capabilities, and precise intelligence
              algorithms for unparalleled surveillance and swift insights across
              maritime and terrestrial domains.{" "}
            </p>
            <a href="https://suhora.com" target="_blank" rel="noopener noreferrer">
              <Button
                label="Learn More"
                type="button"
                className="ml-2 md:ml-8 p-button-outlined"
              />
            </a>
            <Button
              label="Early Access Signup"
              type="button"
              className="ml-2 p-button-raised mt-2" onClick={handleClick2}
            />
          </section>
        </div>
        <div className="col-12 md:col-6 p-2 hidden md:block">
          <img
            src={satellite_view}
            alt="hero-1"
            className="md:ms-auto h-auto md:max-w-full"
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="grid grid-nogutter surface-900 text-800  p-5  md:p-8">
        <div className="col-12 text-center block ">
          <span
            className="block md:text-6xl text-4xl font-bold mb-3 "
            style={{ color: "#fff" }}
          >
            MIRKA
          </span>

          <div className="text-3xl md:text-6xl text-primary font-bold mb-5">
            Rapid | Vigilant | Strategic
          </div>
          <div className="">
            <p className="mt-0 mb-4 text-700 text-md line-height-3 text-gray-50 text-justify">
              In the rapidly evolving defence sector, MIRKA emerges as a
              groundbreaking fusion of human expertise and Artificial
              Intelligence, reshaping strategic operations. This advanced ISR
              platform offers real-time insights, allowing defence and
              intelligence agencies to transition from reactive to proactive
              strategies, ensuring critical targets are never missed. Equipped
              with high-definition sensors and smart algorithms, MIRKA cuts
              through complex data, providing actionable intelligence for both
              maritime and land surveillance. It enhances operational
              effectiveness, offering a 360-degree view of any scenario, making
              it an essential tool for national security.{" "}
            </p>
            <p className="mt-0 mb-4 text-justify text-md text-700 line-height-3 text-gray-50">
              Designed for versatility, MIRKA integrates AI's analytical power
              with strategic foresight, delivering unparalleled situational
              awareness. This synergy between technology and human judgment not
              only sets a new benchmark in defence readiness but also ensures a
              more secure, strategically prepared world.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-nogutter surface-800 text-900 text-gray-50">
      <div className="col-12 md:col-6 overflow-hidden flex flex-wrap">
          <img
            src={ship}
            alt="hero-1"
            className="md:ml-auto block w-1/2 h-auto md:w-full md:h-auto"
            // style={{ clipPath: "polygon(10% 0, 100% 0%, 100% 100%, 0 100%)" }}
          />
        </div>
        <div className="col-12 md:col-6 p-5 md:p-8 text-center md:text-left flex align-items-center ">
          <section>
            <span className="block text-4xl md:text-6xl font-bold mb-1">With MIRKA</span>
            <div className="text-3xl md:text-5xl text-primary font-bold mb-5">
              gain actionable intelligence
            </div>
            <div className="grid">
              <div className="col-12 md:col-4 mb-4 text-gray-50 ">
                <span
                  className="py-1 px-1 shadow-2 mb-3 inline-block bg-gray-100"
                  style={{ borderRadius: "10px" }} 
                >
                 <img src={satellite_svg}alt="" style={{height:"50px"}}/>
                  {/* <span className="pi  text-2xl text-blue-500"> <</span> */}
                </span>
                <div className="text-900 text-xl mb-3 font-medium text-gray-50">
                  Tip & Cue
                </div>
                <span className="text-700 line-height-3 text-gray-50">
                  Automated detections and task cueing
                </span>
              </div>
              <div className="col-12 md:col-4 mb-4 text-gray-50 ">
                <span
                  className="py-1 px-1 shadow-2 mb-3 inline-block bg-gray-100"
                  style={{ borderRadius: "10px" }}
                >
                <img src={orbit1}alt="" style={{height:"50px"}}/>
                </span>
                <div className="text-900 text-xl mb-3 font-medium text-gray-50">
                  Multi-sensor tasking
                </div>
                <span className="text-700 line-height-3 text-gray-50">
                  Leverage multi-spectrum imaging
                </span>
              </div>
              <div className="col-12 md:col-4 mb-4 text-gray-50 ">
                <span
                  className="py-1 px-1 shadow-2 mb-3 inline-block bg-gray-100"
                  style={{ borderRadius: "10px" }}
                >
                  {/* <i className="pi pi-lock text-2xl text-blue-500"></i> */}
                  <img src={light}alt="" style={{height:"50px"}}/>
                </span>
                <div className="text-900 text-xl mb-3 font-medium text-gray-50">
                  Near real-time delivery
                </div>
                <span className="text-700 line-height-3 text-gray-50">
                  Experience blazing fast tasking and delivery
                </span>
              </div>
              <div className="col-12 md:col-4 mb-4 text-gray-50 ">
                <span
                  className="pt-2 px-1 shadow-2 mb-3 inline-block bg-gray-100"
                  style={{ borderRadius: "10px" }}
                >
                  {/* <i className="pi pi-lock text-2xl text-blue-500"></i> */}
                  <img src={ml}alt="" style={{height:"50px"}}/>
                </span>
                <div className="text-900 text-xl mb-3 font-medium text-gray-50">
                  ML based target detection
                </div>
                <span className="text-700 line-height-3 text-gray-50">
                  Automated ML based target detection and identification
                </span>
              </div>
              <div className="col-12 md:col-4 md:mb-4 mb-0 text-gray-50">
                <span
                  className="py-1 px-1 shadow-2 mb-3 inline-block bg-gray-100"
                  style={{ borderRadius: "10px" }}
                >
                  {/* <i className="pi pi-shield text-2xl text-blue-500"></i> */}
                  <img src={change}alt="" style={{height:"50px"}}/>
                </span>
                <div className="text-900 text-xl mb-3 font-medium text-gray-50">
                  Proactive Change mapping
                </div>
                <span className="text-700 line-height-3 text-gray-50">
                  Always on change mapping
                </span>
              </div>
              <div className="col-12 md:col-4 md:mb-4 mb-0 text-gray-50">
                <span
                  className="pt-2 px-1 shadow-2 mb-3 inline-block bg-gray-100"
                  style={{ borderRadius: "10px" }}
                >
                  {/* <i className="pi pi-shield text-2xl text-blue-500"></i> */}
                             {/* <i className="pi pi-lock text-2xl text-blue-500"></i> */}
                             <img src={dish}alt="" style={{height:"50px"}}/>
                </span>
                <div className="text-900 text-xl mb-3 font-medium text-gray-50">
                  Cross sensor insights
                </div>
                <span className="text-700 line-height-3 text-gray-50">
                 Gain unprecented cognizance through sensor convergence
                </span>
              </div>
     
            </div>

    
          </section>
        </div>
  
      </div>
      <div className="grid grid-nogutter surface-0 text-800">

        <div className="col-12 md:col-6 p-5 md:p-8 surface-900 text-center md:text-left flex align-items-center ">
          <section>
            <span className="block text-4xl md:text-6xl font-bold mb-1 text-gray-100">
              Gain new insights
            </span>
            <div className="text-3xl md:text-6xl text-primary font-bold mb-3">
              with super charged imagery
            </div>
            <p className="mt-0 mb-4 text-700 text-justify text-md line-height-3 text-gray-100">
             SUHORA's new <b>super resolution</b> capability helps you get more out of your data. Sensor agnostic nature makes it unique for IMINT requirements.
            </p>

      
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden flex flex-wrap">
          <ImgComparisonSlider className="h-auto">
            <img slot="first" src={input_img} className="" />
            <img slot="second" src={output_img} className="" />
          </ImgComparisonSlider>
          {/* <Splitter style={{ height: "600px", border: "1px grey" }}>
            <SplitterPanel className="flex align-items-center justify-content-center"><Image src={delhi} style={{objectFit:"contain"}}/></SplitterPanel>
            <SplitterPanel className="flex align-items-center justify-content-center"><Image src={satellite_view} style={{objectFit:"contain"}}/></SplitterPanel>
          </Splitter> */}
        </div>
      </div>
      <div className="surface-800 text-700 text-center pt-8" ref={ref}>
        <div className="text-blue-600 font-bold text-2xl pb-3 surface-800">
          Get in Touch
        </div>
        <div className="text-900 font-bold text-3xl md:text-5xl pb-3 surface-800 text-gray-100">
          Early Access Signup Form
        </div>
        <div>
          <section className="text-gray-700 body-font relative">
            <div className="container px-5 py-24 mx-auto surface-800">
              <div className="p-2 w-full">
    
<form className="w-full max-w-lg"> 
  <div className="flex flex-wrap -mx-3 mb-2">
    <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
      {/* <label className="block uppercase tracking-wide text-gray-50 text-xs font-bold mb-3" htmlFor="grid-first-name">
       Name
      </label> */}
      <input className="appearance-none block w-full bg-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" onChange={handleNameChange} name="name" id="grid-first-name" type="text" placeholder="Your Name" required/>
      <p className="text-red-400 text-xs italic">{nameError}</p>
    </div>
    <div className="w-full md:w-1/2 px-3 mb-2 ">
      {/* <label className="block uppercase tracking-wide text-gray-50 text-xs font-bold mb-2" htmlFor="grid-last-name">
        E-mail
      </label> */}
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" onChange={handleEmailChange} name="email" id="grid-last-name" type="text" placeholder="Your Email" required/>
      <p className="text-red-400 text-xs italic">{emailError}</p>
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-2">
    <div className="w-full px-3">
      {/* <label className="block uppercase tracking-wide text-gray-50 text-xs font-bold mb-2" htmlFor="grid-password">
       Contact Number
      </label> */}
      <input className="appearance-none block w-full bg-gray-200 text-gray-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="phone" onChange={handlePhoneChange} id="grid-password" type="tel" placeholder="Contact Number" required/>
      <p className="text-red-400 text-xs italic">{phoneError}</p>
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-2">
    {/* <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-50 text-xs font-bold mb-2" htmlFor="grid-city">
        Subject
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700  rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="Subject" />
    </div> */}

    <div className="w-full md:w-1/3 px-3 mb-2 md:mb-0">
      {/* <label className="block uppercase tracking-wide text-gray-50 text-xs font-bold mb-2" htmlFor="grid-zip">
        Message
      </label> */}
      <textarea className="appearance-none block w-full bg-gray-200 text-gray-700  rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="message" onChange={handleMessageChange} id="grid-zip" type="text" placeholder="Message" required/>
      <p className="text-red-400 text-xs italic">{messageError}</p>
    </div>
  </div>
  <div className="mb-8">
  <Button type="submit" label="Submit" onClick={handleClick}/>
  </div>
</form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default App;
